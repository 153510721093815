<template>
    <div class="post-category" :class="{active: isActive}" @click="emitCategory">{{ category }}</div>
</template>

<script>
export default {
    name: 'CategoryPill',

    props: {
        category: {
            required: true,
            type: String
        },
        activeCategory: {
            required: true,
            type: String
        },
    },

    data: function () {
        return {}
    },

    created() {

    },

    components: {},

    computed: {
        isActive(){
            return this.category === this.activeCategory
        }
    },

    methods: {
        emitCategory(){
            this.$emit('setActiveCategory', this.category)
        }
    }
}


</script>
