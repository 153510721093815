<template>
    <div class="feature-post-wrapper">
        <div class="feature-post-image" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"></div>
        <router-link :to="post.url" class="featured-post" :style="{ backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }">
            <div class="container">
                <div class="post-category">{{ post.category }}</div>

                <h2>{{ post.title }}</h2>

                <div class="blog-post-intro">{{ post.excerpt }}</div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'FeaturedPost',

    props: {
        post: {
            required: true,
            type: Object
        }
    },

    data: function () {
        return {}
    },

    created() {},

    components: {},

    computed: {
        backgroundImage(){
            return require(`../../assets/blog/images/${this.post.image}-large.jpg`)
        }
    },

    methods: {}
}
</script>
