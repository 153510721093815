<template>
    <div class="blog-category-dropdown">
        <div class="dropdown-wrap">
            <div
                class="active-category"
                :class="{opened: dropdownVisible}"
                @click="dropdownVisible = !dropdownVisible"
            >
                {{ activeCategory }}

                <svg class="dropdown-caret"
                     viewBox="0 0 11 8"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.25 1.75L5.5 6.25L9.75 1.75"
                            :stroke="caretColor"
                            stroke-width="2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                </svg>

            </div>

            <div class="category-dropdown-list" v-if="dropdownVisible">
                <div class="category-list-item" v-for="category in categories" :key="category"
                     @click="setActiveCategory(category)">{{ category }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogCategoryDropdown',

    props: {
        categories: {
            required: true,
            type: Array
        },

        activeCategory: {
            required: true,
            type: String
        }
    },

    data: function () {
        return {
            dropdownVisible: false
        }
    },

    created() {

    },

    components: {},

    computed: {
        caretColor() {
            return this.dropdownVisible ? '#d1cb19' : '#020711'
        }
    },

    methods: {
        setActiveCategory(category) {
            this.$emit('setActiveCategory', category)
            this.dropdownVisible = false
        }
    }
}


</script>
