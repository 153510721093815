<template>
    <div class="so-blog blog-archive animated fadeIn">
        <div class="hero-header">
            <div class="container">
                <h1>Super<span class="highlight-green">Script</span></h1>

                <div class="blog-post-intro">
                    This is our forum. A place where we can go deeper into our product, our technologies, our processes and methodologies, our data and knowledge, and the culture that supports it.
                </div>
            </div>
        </div>

        <FeaturedPost :post="latestPost" />

        <div class="blog-archive-post-list">
            <div class="container">

                <div class="blog-archive-categories" >
                    <BlogCategoryDropdown
                        v-if="categoryDropdownVisible"
                        :categories="blogCategories"
                        :activeCategory="activeCategory"
                        @setActiveCategory="setActiveCategory"
                    />

                    <template v-else>
                        <CategoryPill
                            v-for="category in blogCategories"
                            :category="category"
                            :activeCategory="activeCategory"
                            :key="category"
                            @setActiveCategory="setActiveCategory"
                        />
                    </template>
                </div>

                <div class="blog-posts">
                    <ArchiveBlogPostItem v-for="post in filteredAPosts" :key="post.title" :post="post" />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import ArchiveBlogPostItem from '@/components/blog/ArchiveBlogPostItem.vue'
import CategoryPill from '@/components/blog/CategoryPill.vue'
import posts from '@/views/blog/posts/posts'
import FeaturedPost from '@/components/blog/FeaturedPost.vue'
import metadata from '@/util/metadata'
import BlogCategoryDropdown from '@/components/blog/BlogCategoryDropdown.vue'

export default {
    name: 'BlogArchive',

    metaInfo: {
        meta: metadata.blog,
        title: 'Blog | Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/blog'}
        ]
    },

    props: {},

    data: function () {
        return {
            activeCategory: 'All',
            posts
        }
    },

    created() {
        this.activeCategory = this.categoryQuery || 'All'
    },

    components: {BlogCategoryDropdown, FeaturedPost, CategoryPill, ArchiveBlogPostItem},

    computed: {
        blogCategories() {
            const categories = this.posts.map(post => post.category)

            return ['All', ...new Set(categories)]
        },

        filteredAPosts() {
            const allButFeaturedPost = this.posts.slice(1)

            if (this.activeCategory === 'All') {
                return allButFeaturedPost
            } else {
                const filteredPosts = this.posts.filter(post => post.category === this.activeCategory)

                if(filteredPosts.length){
                    return filteredPosts
                }

                else{
                    this.activeCategory = 'All'

                    return allButFeaturedPost
                }
            }
        },

        latestPost() {
            return this.posts[0]
        },

        categoryQuery() {
            return this.$route.query.category
        },

        categoryDropdownVisible(){
            return this.$store.getters.getScreenWidth < 768
        }
    },

    methods: {
        setActiveCategory(category) {
            this.activeCategory = category

            const queryCategory = category === 'All' ? undefined : category

            if(queryCategory !== this.categoryQuery){
                this.$router.replace({query: {category: queryCategory}})
            }
        }
    }
}

</script>
