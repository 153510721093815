export default [
    {
        category: 'Engineering',
        title: 'You\'re not the boss of me: empowered teams and where to find them',
        excerpt: 'We work in empowered teams. With users as our true stakeholders, we experiment, follow the data and only then – deliver.',
        url: '/blog/you-are-not-the-boss-of-me-empowered-teams-and-where-to-find-them',
        image: 'tennis-room-meeting'
    },
    {
        category: 'Culture',
        title: 'How more downtime drives creativity and innovation',
        excerpt: 'As more and more companies are pushing creative ways of implementing a more sustainable work-life balance model, we\'re opting for unlimited vacation. That\'s just how we roll.',
        url: '/blog/how-more-downtime-drives-creativity-and-innovation',
        image: 'office-chairs'
    },
    {
        category: 'Engineering',
        title: 'BEAM me up, Scotty: A short talk with Saša Jurić',
        excerpt: 'Saša is a household name in the Elixir community, and his book, Elixir in Action, is a staple we at Superology already use for our backend developer onboarding.',
        url: '/blog/BEAM-me-up-scotty-a-short-talk-with-sasa-juric',
        image: 'sasa-juric'
    },
    {
        category: 'Data',
        title: 'How we use data to advance user experience with ClickHouse',
        excerpt: 'Superology uses quantitative data to create reports, analyses them using statistical tools, and creates randomized experimentation processes. We use this data to modify our customer experience and increase the satisfaction and usefulness of our app.',
        url: '/blog/how-we-use-data-to-advance-user-experience-with-clickhouse',
        image: 'superology-halls',
    },
    {
        category: 'Platform',
        title: 'Fastly: Pushing content closer to users',
        excerpt: 'Superology leads the way when it comes to fast-paced technological innovation in the betting industry. Conventional systems are changing fast to accommodate millions of users demanding and consuming sports content constantly, even at 5 AM.',
        url: '/blog/fastly-pushing-content-closer-to-users',
        image: 'fastly'
    }
]
