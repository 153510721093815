<template>
    <div class="post-preview">
        <router-link :to="post.url">
            <img :src="featuredImageUrl" :alt="post.title">
        </router-link>

        <div class="tags">
            <span class="tag">{{ post.category }}</span>
        </div>

        <router-link :to="post.url"><h4>{{ post.title }}</h4></router-link>

        <div class="excerpt">{{ excerpt }}</div>
    </div>
</template>

<script>

export default {
    name: 'ArchiveBlogPostItem',

    props: {
        post: {
            required: true,
            type: Object
        }
    },

    data: function () {
        return {}
    },

    created() {

    },

    components: {},

    computed: {
        featuredImageUrl() {
            return require(`../../assets/blog/images/${this.post.image}.jpg`)
        },

        excerpt() {
            return `${this.trimExcerpt(this.post.excerpt)}...`
        },
    },

    methods: {
        /*
            removes any of the special characters if the trimmed excerpt happens to land on it.
            this makes sure we don't end up with added dots on top of special characters
        */
        trimExcerpt(fullExcerpt) {
            const specialCharacters = [',', '.', '!', '?', '-']
            const trimmedExcerpt = fullExcerpt.replace(/^(.{160}[^\s]*).*/, '$1')
            const lastCharacter = trimmedExcerpt.charAt(trimmedExcerpt.length - 1)

            return specialCharacters.includes(lastCharacter) ? trimmedExcerpt.slice(0, -1) : trimmedExcerpt
        }
    }
}


</script>
